import { all, put, takeEvery } from "redux-saga/effects";
import {
  addUpdatesToCompliant,
  compliantClosure,
  getComplaintsTotalCount,
  takeoverCompliant
} from "../../Services/database";
import { getFileUrl, uploadCompliantProof } from "../../Services/storage";
import { isValidArray } from "../../Services/validators";
import { bucketNames } from "../../Utils/constants";
import { setErrorStatus, setSuccessStatus } from "../status/action";
import store from "../store";

export const actionTypes = {
  SET_COMPLAINTS_DATA: "SET_COMPLAINTS_DATA",
  UPDATE_COMPLAINTS_DATA: "UPDATE_COMPLAINTS_DATA",
  FETCH_UPDATE_COMPLAINTS_ASSETS: "FETCH_UPDATE_COMPLAINTS_ASSETS",
  FETCH_COMPLAINTS_ASSETS: "FETCH_COMPLAINTS_ASSETS",
  FIX_COMPLAINTS: "FIX_COMPLAINTS",
  COMPLAINT_TAKEOVER: "COMPLAINT_TAKEOVER",
  SET_FILTER: "SET_FILTER",
  SET_SEARCH_KEY: "SET_SEARCH_KEY",
  SET_SORT_BY: "SET_SORT_BY"
};

function* setComplaintsWorker(action) {
  try {
    yield setComplaintsLoading(true);
    const phoneNumber = store.getState().auth.data.phoneNumber;

    const count = yield getComplaintsTotalCount({
      filters: action.payload.filters,
      phoneNumber: phoneNumber
    });
    yield put({
      type: "SET_COMPLAINTS",
      payload: {
        data: action.payload.complaints
      }
    });
    yield put({
      type: "SET_COMPLAINTS_COUNT",
      payload: {
        data: count
      }
    });
    yield setComplaintsLoading(false);
  } catch (error) {
    yield setComplaintsLoading(false);
    setErrorStatus(error);
  }
}

function* fetchComplaintsAssetsWorker(action) {
  try {
    yield setComplaintsLoading(true);
    const selectedComplaint =
      store.getState().complaints.data[action.payload.complaintId];
    let proof = {};
    const images = [];
    if (isValidArray(selectedComplaint.assets) && !selectedComplaint?.proof) {
      for (const asset of selectedComplaint.assets) {
        const file = yield getFileUrl(asset);
        if (file.type === "image") {
          images.push(file.url);
        } else if (file.type === "audio") {
          proof = { ...proof, audio: file.url };
        }
      }

      yield put({
        type: "UPDATE_COMPLAINTS_WITH_ASSETS",
        payload: {
          complaintId: selectedComplaint.documentId,
          data: {
            ...selectedComplaint,
            proof: {
              ...proof,
              ...(isValidArray(images) ? { images: images } : {})
            }
          }
        }
      });
    }
    yield setComplaintsLoading(false);
  } catch (error) {
    setErrorStatus(error);
  }
}

function* fetchUpdateComplaintAssetsWorker(action) {
  try {
    yield setComplaintsLoading(true);
    const updates =
      store.getState().complaints.data?.[action.payload.complaintId].updates;
    let newUpdate = [];
    for (let i = 0; i < updates.length; i++) {
      let assets = {};
      let images = [];
      for (let j = 0; j < updates[i].proof.length; j++) {
        const file = yield getFileUrl(updates[i].proof[j]);
        if (file.type === "image") {
          images.push(file.url);
        } else if (file.type === "audio") {
          assets = { ...assets, audio: file.url };
        }
      }
      newUpdate.push(
        (updates[i] = {
          ...updates[i],
          assets: {
            ...assets,
            ...(isValidArray(images) ? { images: images } : {})
          }
        })
      );
    }

    yield put({
      type: "UPDATE_COMPLAINTS_WITH_ASSETS",
      payload: {
        complaintId: action.payload.complaintId,
        data: {
          ...store.getState().complaints.data?.[action.payload.complaintId],
          updates: newUpdate
        }
      }
    });
    yield setComplaintsLoading(false);
  } catch (error) {
    yield setComplaintsLoading(false);
    setErrorStatus(error);
  }
}

function* fixComplaintsWorker(action) {
  try {
    yield setComplaintsLoading(true);

    let imageFileUrls = [];
    let audioFileUrl = [];

    if (isValidArray(action.payload.proof?.image)) {
      for (let index = 0; index < action.payload.proof.image.length; index++) {
        yield uploadCompliantProof(
          action.payload.proof.image[index],
          action.payload.complaintId,
          action.payload.locationId
        );

        imageFileUrls.push(
          `${bucketNames.defaultBucket}/${action.payload.locationId}/complaints/${action.payload.complaintId}/closure/${action.payload.proof.image?.[index]?.name}`
        );
      }
    }

    if (action.payload.proof?.audio) {
      yield uploadCompliantProof(
        action.payload.proof.audio,
        action.payload.complaintId,
        action.payload.locationId
      );
      audioFileUrl.push(
        `${bucketNames.defaultBucket}/${action.payload.locationId}/complaints/${action.payload.complaintId}/closure/${action.payload.proof.audio?.name}`
      );
    }

    yield compliantClosure(
      action.payload.complaintId,
      action.payload.employeeId,
      isValidArray(action.payload.proof.image) && action.payload.proof.audio
        ? [...imageFileUrls, ...audioFileUrl]
        : isValidArray(action.payload.proof.image)
        ? imageFileUrls
        : action.payload.proof.audio
        ? audioFileUrl
        : ""
    );

    setSuccessStatus("Submitted successfully");

    if (action.payload.navigate) {
      action.payload.navigate("/complaints");
    }

    yield setComplaintsLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield setComplaintsLoading(false);
  }
}

function* complaintTakeoverWorker(action) {
  try {
    yield setComplaintsLoading(true);

    yield takeoverCompliant(
      action.payload.complaintId,
      action.payload.employeeData
    );
    const complaintData = store.getState().complaints.data;
    yield put({
      type: "SET_COMPLAINTS",
      payload: {
        data: {
          ...complaintData,
          [action.payload.complaintId]: {
            ...complaintData?.[action.payload.complaintId],
            takenOverBy: action.payload.employeeData
          }
        }
      }
    });

    setSuccessStatus("Compliant Taken over successfully");

    yield setComplaintsLoading(false);
  } catch (error) {
    console.error("complaintTakeoverWorker", error);
    setErrorStatus(error);
    yield setComplaintsLoading(false);
  }
}

function* updateComplaintsWorker(action) {
  try {
    yield setComplaintsLoading(true);

    let imageFileUrls = [];
    let audioFileUrl = [];

    if (isValidArray(action.payload.proof.image)) {
      for (let index = 0; index < action.payload.proof.image.length; index++) {
        yield uploadCompliantProof(
          action.payload.proof.image[index],
          action.payload.complaintId,
          action.payload.locationId
        );
        imageFileUrls.push(
          `${bucketNames.defaultBucket}/${action.payload.locationId}/complaints/${action.payload.complaintId}/closure/${action.payload.proof.image?.[index]?.name}`
        );
      }
    }

    if (action.payload.proof.audio) {
      yield uploadCompliantProof(
        action.payload.proof.audio,
        action.payload.complaintId,
        action.payload.locationId
      );
      audioFileUrl.push(
        `${bucketNames.defaultBucket}/${action.payload.locationId}/complaints/${action.payload.complaintId}/closure/${action.payload.proof.audio?.name}`
      );
    }

    yield addUpdatesToCompliant(
      action.payload.remarks,
      action.payload.complaintId,
      isValidArray(action.payload.proof.image) && action.payload.proof.audio
        ? [...imageFileUrls, ...audioFileUrl]
        : isValidArray(action.payload.proof.image)
        ? imageFileUrls
        : action.payload.proof.audio
        ? audioFileUrl
        : ""
    );

    setSuccessStatus("Update added successfully");

    yield setComplaintsLoading(false);
  } catch (error) {
    console.error("updateComplaintsWorker", error);
    setErrorStatus(error);
    yield setComplaintsLoading(false);
  }
}

function* setFilterWorker(action) {
  try {
    yield put({
      type: "PUT_FILTER",
      payload: { data: action.payload.data }
    });
  } catch (error) {
    console.error("setFilterWorker", error);
    setErrorStatus(error);
  }
}

function* setSearchKeyWorker(action) {
  try {
    yield put({
      type: "PUT_SEARCH_KEY",
      payload: { data: action.payload.data }
    });
  } catch (error) {
    setErrorStatus(error);
  }
}

function* setSortByWorker(action) {
  try {
    yield put({
      type: "PUT_SORT_BY",
      payload: { data: action.payload.data }
    });
  } catch (error) {
    setErrorStatus(error);
  }
}

export default function* complaintWatcher() {
  yield all([
    takeEvery("SET_COMPLAINTS_DATA", setComplaintsWorker),
    takeEvery("FIX_COMPLAINTS", fixComplaintsWorker),
    takeEvery("UPDATE_COMPLAINTS_DATA", updateComplaintsWorker),
    takeEvery("FETCH_COMPLAINTS_ASSETS", fetchComplaintsAssetsWorker),
    takeEvery(
      "FETCH_UPDATE_COMPLAINTS_ASSETS",
      fetchUpdateComplaintAssetsWorker
    ),
    takeEvery("COMPLAINT_TAKEOVER", complaintTakeoverWorker),
    takeEvery("SET_FILTER", setFilterWorker),
    takeEvery("SET_SEARCH_KEY", setSearchKeyWorker),
    takeEvery("SET_SORT_BY", setSortByWorker)
  ]);
}

function* setComplaintsLoading(bool) {
  yield put({
    type: "SET_COMPLAINTS_LOADING",
    payload: {
      loading: bool
    }
  });
}
